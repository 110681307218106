var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-module"
  }, [_c('product-filters', {
    on: {
      "filtersChange": _vm.applyFiltersEvent
    }
  }), _c('div', {
    staticClass: "table-container"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('sygni-container-title', {
    staticClass: "header"
  }, [_vm._v(" Products list ")])], 1)])]), _c('div', {
    staticClass: "table-container__table"
  }, [_c('product-table', {
    ref: "productTable",
    attrs: {
      "tableData": _vm.productsTable,
      "sortingQueryAction": 'products/setProductsTableQuery',
      "getItemsAction": 'products/getProducts',
      "tablePerPageMutation": "products/setProductsTablePerPage",
      "setTableBusyMutation": 'products/setProductsTableBusy',
      "busy": _vm.isBusy,
      "showPagination": true,
      "showPageNumberOptions": true
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }